import {
  getAuth,
  GoogleAuthProvider,
  signInWithRedirect,
  signInWithPopup,
  getRedirectResult,
  onIdTokenChanged,
} from "firebase/auth";

import { authStore } from "@/store/auth.js";
import { getDatabase, ref, set, get } from "firebase/database";

export const authUtils = {
  startAuth: () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    if (authUtils.isMobile()) {
      signInWithRedirect(auth, provider);
    } else {
      signInWithPopup(auth, provider);
    }
  },
  checkForRedirectAuth: (router) => {
    const auth = getAuth();
    if (authUtils.isMobile()) {
      console.debug(
        "User is on a mobile device - checking if redirect auth exists"
      );
      getRedirectResult(auth).then((result) => {
        if (result !== null && result.user) {
          authStore.setUserId(result.user.uid);
          authStore.setIdToken(result.user.getIdToken());
          router.push("/chats");
        } else {
          console.log("no auth redirect result");
        }
      });
    } else {
      console.debug("User is not on a mobile device - not checking auth yet");
    }
  },
  isMobile: () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  },
  setupAuth: (router) => {
    onIdTokenChanged(getAuth(), (user) => {
      if (user) {
        authStore.setUserId(user.uid);
        user.getIdToken().then((token) => {
          console.debug("token updated");
          authStore.setIdToken(token);
        });

        const db = getDatabase();
        get(ref(db, "users/" + user.uid)).then((snapshot) => {
          if (snapshot.exists()) {
            return;
          } else {
            set(ref(db, "users/" + user.uid), {
              state: "Signed Up Only",
            });
          }
        });

        router.push("/chats");
      } else {
        authUtils.checkForRedirectAuth();
      }
    });
  },
};
