<template>
  <div>
    <div
      class="modal fade"
      id="userModal"
      tabindex="-1"
      aria-labelledby="userModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="userModalLabel">
              Enter Your Information
            </h5>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="nameInput" class="form-label">Name</label>
              <input
                type="text"
                class="form-control"
                id="nameInput"
                v-model="name"
                :class="{ 'is-invalid': !isValidName }"
              />
              <div class="invalid-feedback" v-if="!isValidName">
                Please enter a valid name (2-30 characters, no numbers).
              </div>
            </div>
            <div class="mb-3">
              <label for="languageSelect" class="form-label"
                >Native Language</label
              >
              <select
                class="form-select"
                id="languageSelect"
                v-model="selectedLanguage"
              >
                <option
                  v-for="(language, code) in languageOptions"
                  :key="code"
                  :value="language.name"
                >
                  {{ language.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="saveData"
              :disabled="!fieldsAreValid"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authStore } from "@/store/auth";
import { Modal } from "bootstrap";
import { getDatabase, ref, update } from "firebase/database";
import Languages from "@/data/Language";

export default {
  name: "NewStudentOnboarding",
  data() {
    return {
      modal: null,
      name: "",
      isValidName: true,
      selectedLanguage: "",
      languageOptions: Languages,
    };
  },
  watch: {
    // todo: i need a unit test for this regex. def feels like a place we'd see regression with a bad change
    // Dani molina-hurtado
    // matush vi
    // mat vimal
    // mat vimal-es-varan
    // 晴斗 vimaesl
    // ハルト
    // ハルト・ヴィマエス
    name(newName) {
      const nameRegex = /^[\p{L}\p{M}]+(?:[- ]?[\p{L}\p{M}]+)*$/u; // letters only, spaces or hyphens for multi-word names
      this.isValidName =
        nameRegex.test(newName) &&
        newName.trim().length >= 2 &&
        newName.trim().length <= 30;
    },
  },
  methods: {
    saveData() {
      console.debug(
        "Saving Name: ",
        this.name,
        " Language: ",
        this.selectedLanguage
      );
      const userRef = ref(getDatabase(), `users/${authStore.getUserId()}`);
      update(userRef, {
        state: null,
        name: this.name.trim(),
        nativeLanguage: this.selectedLanguage,
      })
        .then(() => {
          console.debug("User data updated successfully");
        })
        .catch((error) => {
          console.error("Error updating user data:", error);
        });
      this.modal.hide();
    },
  },
  computed: {
    fieldsAreValid() {
      return this.selectedLanguage && this.isValidName;
    },
  },
  mounted() {
    this.modal = new Modal(document.getElementById("userModal"), {
      keyboard: false,
      backdrop: "static",
    });
    this.modal.show();
  },
};
</script>
