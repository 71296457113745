import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import ChatView from "@/views/ChatView.vue";
import AboutUsView from "@/views/AboutUsView.vue";
import NotFoundView from "@/views/NotFoundView.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/app.html",
    name: "Home2",
    component: HomeView,
  },
  {
    path: "/chats",
    name: "Chats",
    component: ChatView,
  },
  {
    path: "/about-us",
    name: "About Us",
    component: AboutUsView,
  },
  {
    path: "/:catchAll(.*)*",
    name: "Not Found",
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.name || "ArticuLearn";
  next();
});

export default router;
