<template>
  <div class="feature-section">
    <h1 class="title">Our Features</h1>
    <div class="card-container">
      <div class="feature-card" v-for="(feature, index) in tabs" :key="index" :class="['feature-card', `card-${index}`]">
        <div class="feature-content">
          <div class="feature-image">
            <img :src="feature.image" class="small-image" />
          </div>
          <div class="feature-details">
            <h3>{{ feature.label }}</h3>
            <div class="feature-description">
            <p v-html="feature.content"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: [
        { 
          label: "Personalized Lessons", 
          content: "With the AI tutor's ability to retain past conversations, you can <strong class='bold-text'>build upon previous discussions</strong>. " +
                   "The AI tutor will <strong class='bold-text'>adapt lessons to your learning style</strong>.", 
          image:require("@/assets/interactive-lessons.png") 
        },
        { 
          label: "Real-World Practice", 
          content: "Practice real-life topics to <strong class='bold-text'>enhance your everyday conversational skills</strong>. " + 
                   "Explore topics of interest: travel, relationships, and more.", 
          image: require("@/assets/real-world-practice.png")
        },
        { 
          label: "Expert Tutor", 
          content: "Get <strong class='bold-text'>personalized feedback</strong> on your grammar to help you improve faster. " + 
                   "<strong class='bold-text'>Explore a wide range of topics</strong>, as the AI tutor possesses a wealth of knowledge.", 
          image: require("@/assets/personalized-feedback.png")
        },
        { 
          label: "Flexible Learning", 
          content: "ArticuLearn creates a <strong class='bold-text'>supportive environment</strong> for learning without judgment. " +  
                   "Learn at <strong class='bold-text'>your pace</strong>. Talk <strong class='bold-text'>anytime</strong> and from <strong class='bold-text'>anywhere</strong>",
          image:require("@/assets/flexible-learning.png") 
        }
      ],
    };
  },
};
</script>

<style scoped>
@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px); 
  }
  100% {
    transform: translateY(0);
  }
}

.feature-section {
  width: 100vw;
  background-image: linear-gradient(to bottom, #fff 50%, rgba(235, 136, 45, 0.7) 50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.title {
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  color: #000;
  font-size: 43px;
  padding-top: 70px;
  padding-bottom: 70px;
}

.card-container {
  display: flex;
  padding-bottom: 50px;
  justify-content: center;
  width: calc(100% - 30px);
}

.feature-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 -3px 20px -5px rgba(235, 136, 45, 0.8), 
              0 5px 10px rgba(0, 0, 0, 0.3);
  margin: 0 15px;
  width: 275px; 
  height: 350px;
  max-height: 100%;
  animation: floatAnimation 3s ease-in-out infinite;
}

.card-1, .card-3 {
  margin-top: 90px; 
}

.feature-content {
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center; 
  justify-content: space-between; 
  max-height: 100%;
}

.feature-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.feature-details h3 {
  font-size: 16px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  color: #000000;
  padding-bottom: 5px;
  letter-spacing: 1px;
  margin-top: 10px;
}

.small-image  {
  max-width: 60%; 
  max-height: 60%; 
  display: block; 
  margin: 0 auto;
  border-radius: 100%; 
  overflow: hidden; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); 
}

.feature-image  {
  padding-top: 5px;
  padding-bottom: 15px;
  align-items: center;
}

.feature-description {
  flex: 1; 
  display: flex; 
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: flex-start; 
}

.feature-description p {
  list-style-type: disc; 
  padding-left: 15px; 
  padding-right: 15px; 
  text-align: center; 
  font-size: 13px;
  color: #737373;
  font-family: "Open Sans", sans-serif;
}

.bold-text {
  font-weight: bold;
  color: #2d90eb;
}

@media (max-width: 900px) { 
  .feature-description p {
    font-size: 11.5px;
  }
}

@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
    align-items: center;
  }

  .feature-card {
    margin: 25px 0;
    animation: none;
  }

  .feature-content {
    flex-direction: column; 
    height: auto; 
  }

  .feature-section {
    background-image: none;
    background: #fff;
  }
}
</style>

