<template lang="en">
  <div class="chat">
    <div class="header">
      <h2 class="title"></h2>
      <div class="actions">
        <div class="current-language">
          <img :src="languageFlagSrc" :alt="languageFlagAltText" class="language-icon"/>
        </div>
        <button class="button sign-out" @click="signOut">Sign Out</button>
      </div>
    </div>
    <div v-if="!dialogId">
      <button class="button new-chat" :disabled="newChatDisabled" @click="newChat">New Chat</button>
    </div>
    <div v-else>
      <ChatScreen :dialogId="dialogId" @newChatRequest="newChat"/>
    </div>
    <div v-if="showUserInfoOnboarding">
      <NewStudentOnboarding />
    </div>
    <div v-if="showLangOnboarding">
      <NewLanguageOnboarding />
    </div>
  </div>
</template>

<script>
import ChatScreen from "@/components/ChatScreen.vue";
import Languages from "@/data/Language.js";
import NewStudentOnboarding from "@/components/NewStudentOnboarding.vue";
import NewLanguageOnboarding from "@/components/NewLanguageOnboarding.vue";
import { authStore } from "@/store/auth.js";
import { flagsStore } from "@/store/countryFlags.js";
import { getAuth, signOut } from "firebase/auth";
import { getDatabase, ref, push, set, onValue, get, query, orderByChild, limitToLast } from "firebase/database";

export default {
  name: "ChatView",
  components: {
    ChatScreen,
    NewStudentOnboarding,
    NewLanguageOnboarding,
  },
  data() {
    return {
      dialogId: null,
      showUserInfoOnboarding: false,
      showLangOnboarding: false,
      studentInfo: -1,
      newChatDisabled: true,
      changeLanguageDisabled: true,
      languageFlagSrc: flagsStore.getFlag("unknown"),
      languageFlagAltText: "Country flag for selected language",
    };
  },
  watch: {
    studentInfo(newVal, oldVal) {
      if (newVal === null || !("name" in newVal)) {
        console.debug("showing basic onboarding");
        this.showUserInfoOnboarding = true;
      } else {
        this.studentInfo = newVal;
        this.showUserInfoOnboarding = false;
        if ("selectedLanguage" in newVal) {
          console.debug("selectedLanguage exists. enabling buttons");
          this.changeLanguageDisabled = false;
          this.languageFlagSrc = flagsStore.getFlag(newVal.selectedLanguage);
          this.languageFlagAltText = Languages[newVal.selectedLanguage].altText;
          this.renderOngoingChatElseAllowNew();
        } else {
          console.debug("no selected language. showing lang onboarding");
          this.showLangOnboarding = true;
        }
      }
    },
  },
  methods: {
    renderOngoingChatElseAllowNew() {
      const db = getDatabase();
      const dialogRef = ref(
        db,
        "dialogs/" + authStore.userId + "/" + this.studentInfo.selectedLanguage
      );
      get(query(dialogRef, orderByChild("startedAt"), limitToLast(1)))
        .then((snapshot) => {
          if (snapshot.exists()) {
            snapshot.forEach((latestDialog) => {              
              this.dialogId = latestDialog.key;
            });
          } else {
            this.newChatDisabled = false;
          }
        });
    },
    newChat() {
      if (!authStore.checkAuthOrElseRedirect()) return;
      const db = getDatabase();
      // note: push() creates a key client side only. It does not write to the database
      // until the next step. So, there's no race condition where the server sees the
      // path before the additional data is written.
      const dialogMetaRef = push(ref(db, "dialog-meta"));
      set(dialogMetaRef, {
        userId: authStore.userId,
        lang: this.studentInfo.selectedLanguage,
      });
      this.dialogId = dialogMetaRef.key;
    },
    watchStudentInfo() {
      const db = getDatabase();
      onValue(
        ref(db, "users/" + authStore.userId),
        (snapshot) => {
          const data = snapshot.val();
          this.studentInfo = data;
        },
        (error) => {
          console.error("read failed:", error);
        }
      );
    },
    signOut() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          console.log("sign out successful");
          authStore.setUserId(null);
          this.$router.push("/");
        })
        .catch((error) => {
          // An error happened.
          console.error("sign out error", error);
        });
    },
  },
  mounted() {
    authStore.checkAuthOrElseRedirect();
    if (authStore.userId) {
      this.watchStudentInfo();
    }
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #eb882d;
  color: #fff;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

.actions {
  display: flex;
  align-items: center;
}

.language-icon {
  width: 3em;
}

.button {
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: #fff;
  color: #2d90eb;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
button:disabled {
  background-color: #cccccc;
  color: #666666;
  border: none;
  cursor: not-allowed;
  opacity: 0.5;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.button.sign-out {
  background-color: transparent;
  color: #fff;
}

.button.new-chat {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(
    -50%,
    -50%
  ); /* Shift the button back by half of its own width and height to center it */
  width: 30%; /* Button width is 30% of the screen */
  padding: 20px;
  font-size: 24px;
  text-align: center;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: #eb882d;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for 3D effect */
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}
.button.new-chat:hover {
  background-color: #eb882d;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.2); /* Increase shadow when hovering */
  color: white; /* Keep text color white when hovering */
}
.button.new-chat:active {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%) scale(0.95);
}
.button.new-chat:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}
</style>
