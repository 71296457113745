import { reactive } from "vue";
import router from "../router/index";

// Note: this is stored in memory so refreshing the page will clear the state
// docs: https://vuejs.org/guide/scaling-up/state-management.html#simple-state-management-with-reactivity-api
export const authStore = reactive({
  userId: null,
  idToken: null,
  getUserId() {
    return this.userId;
  },
  setUserId(userId) {
    this.userId = userId;
  },
  getIdToken() {
    return this.idToken;
  },
  setIdToken(idToken) {
    this.idToken = idToken;
  },
  checkAuthOrElseRedirect() {
    if (this.userId === null) {
      router.push("/");
      return false;
    }
    return true;
  },
});
