<template>
  <div>
    <div :class="{ 'solid-border': isSolidBorder }" class="header">
      <div class="hero">
        <img src="@/assets/home-page.png" alt="ArticuLearn Logo" class="logo" />
      </div>
      <div class="nav-bar">
        <div class="hamburger" @click="toggleMenu">
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
        </div>

        <div v-if="isMobileMenuOpen" class="mobile-menu">
          <div class="mobile-header">
            <div class="close-icon" @click="toggleMenu">X</div>
            <img
              src="@/assets/home-page.png"
              alt="ArticuLearn Logo"
              class="logo"
            />
          </div>

          <div class="mobile-body">
            <ul>
              <li>
                <a href="https://blog.articulearnai.com/" class="nav-link"
                  >Blogs</a
                >
              </li>
              <li>
                <a href="https://knowledge.articulearnai.com/" class="nav-link"
                  >Help Center</a
                >
              </li>
              <li><a @click="startAuth" class="nav-link">Log in</a></li>
              <li><a @click="startAuth" class="nav-button">Get started</a></li>
            </ul>
          </div>
        </div>

        <nav class="desktop-nav">
          <ul>
            <li>
              <a href="https://blog.articulearnai.com/" class="nav-link"
                >Blogs</a
              >
            </li>
            <li>
              <a href="https://knowledge.articulearnai.com/" class="nav-link"
                >Help Center</a
              >
            </li>
            <li><a @click="startAuth" class="nav-link">Log in</a></li>
            <li><a @click="startAuth" class="nav-button">Get started</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { authUtils } from "@/scripts/authUtils.js";

export default {
  name: "NavigationBar",
  data() {
    return {
      isMobileMenuOpen: false,
      isSolidBorder: false,
      scrollThreshold: 100,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    startAuth: authUtils.startAuth,
    toggleMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    handleScroll() {
      this.isSolidBorder = window.scrollY > this.scrollThreshold;
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 85px;
  padding: 0 20px;
  padding-left: 5px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: border-bottom-color 0.3s ease;
}

.solid-border {
  border-bottom: 1.5px solid #eb882d;
}

.hero {
  overflow: hidden;
  box-sizing: border-box;
  margin-top: 20px;
  margin-bottom: 20px;
}

.logo {
  width: 18vw;
  height: auto;
  float: left;
}

.mobile-body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eb882d;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 100px;
  padding-left: 15px;
  padding-right: 15px;
}

.mobile-header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 85px;
  padding: 0 20px;
  padding-left: 5px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.mobile-menu .nav-link {
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
  text-decoration: none;
  text-align: left;
}

.mobile-menu .nav-button {
  border-radius: 30px;
  background-color: #2d90eb;
  color: #fff;
  border: none;
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  display: inline-block;
  width: 100%;
  padding: 5px;
}

.mobile-menu .close-icon {
  position: absolute;
  right: 27px;
  color: #eb882d;
  font-size: 35px;
  font-weight: bold;
  cursor: pointer;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 35px;
  height: 3px;
  background-color: #eb882d;
  margin: 5px 0;
}

/* For mobile screens */
@media (max-width: 767px) {
  .hamburger {
    display: flex;
  }

  .desktop-nav {
    display: none;
  }

  .hero {
    overflow: visible;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .logo {
    width: 45vw;
  }
}

/* For desktop screens */
@media (min-width: 768px) {
  .desktop-nav {
    margin-top: 20px;
    display: block;
  }

  .desktop-nav ul {
    list-style: none;
    display: flex;
    justify-content: center;
  }

  .desktop-nav .nav-link {
    padding: 5px 5px;
    text-decoration: none;
    color: #eb882d;
    font-weight: bold;
    font-size: 20px;
    font-family: "Open Sans", sans-serif;
    display: inline-block;
    margin: 0 8px;
    transition: color 0.3s ease;

    &:hover {
      color: rgba(235, 136, 45, 0.5);
      cursor: pointer;
    }
  }

  .desktop-nav .nav-button {
    padding: 5px 20px;
    border-radius: 30px;
    background-color: #2d90eb;
    color: #fff;
    border: none;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
    font-family: "Open Sans", sans-serif;
    display: inline-block;
    margin: 0 15px;

    &:hover {
      background-color: #c0def9;
      color: #2d90eb;
    }
  }

  .mobile-menu {
    display: none;
  }
}

/* For extra-large screens */
@media (min-width: 1900px) {
  .logo {
    width: 12vw;
  }
}
</style>
