// Vue
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index";
import { createHead } from "@unhead/vue";

// Bootstrap
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

// Google Analytics
import VueGtag from "vue-gtag";

// HubSpot Analytics
import hubsAnaltyics from "@/scripts/hubspot.js";

// Firebase
import { initializeApp } from "firebase/app";
import {
  getDatabase,
  ref,
  push,
  set,
  connectDatabaseEmulator,
} from "firebase/database";
import firebaseConfig from "./firebase-config.js";

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
if (isLocalhost()) {
  console.log("Localhost detected! Setting up local database emulator.");
  const db = getDatabase(firebaseApp);
  connectDatabaseEmulator(db, "127.0.0.1", 9000);
  const newPostRef = push(ref(db, "localDbStarted"));
  set(newPostRef, {
    started: true,
    startedAt: Date.now(),
  }).catch((error) => {
    console.log(error);
  });
}

// method to check if is localhost
function isLocalhost() {
  return location.hostname === "localhost" || location.hostname === "127.0.0.1";
}

// Initialize HTML Head
const head = createHead();

// Initialize Vue
const vue = createApp(App)
  .use(router)
  .use(head)
  .use(VueGtag, {
    config: { id: "G-ZY847CPJ46" },
  });
vue.mount("#app");
console.debug(`==== ${process.env.NODE_ENV} Restarted ====`);
