<template>
  <span class="dot" :class="{ 'dot-fade': dotFade[0] }"></span>
  <span class="dot" :class="{ 'dot-fade': dotFade[1] }"></span>
  <span class="dot" :class="{ 'dot-fade': dotFade[2] }"></span>
</template>

<script>
export default {
  data() {
    return {
      dotFade: [false, false, false],
    };
  },
  mounted() {
    this.timer = setInterval(() => {
      this.dotFade = [true, false, false];
      setTimeout(() => {
        this.dotFade = [false, true, false];
        setTimeout(() => {
          this.dotFade = [false, false, true];
        }, 500);
      }, 500);
    }, 1500);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>

<style>
.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
  background-color: #000;
  opacity: 0.3;
  animation: dot-fade-in-out 1.5s infinite;
}

.dot-fade {
  animation: dot-fade-in-out 1.5s infinite;
  animation-delay: 0.5s;
  opacity: 0.1;
}

@keyframes dot-fade-in-out {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.3;
  }
}
</style>
