<template>
  <div v-if="isTutor">
    <div v-if="isMessageLoading">
      <TutorMessage :isLoading="true" />
    </div>
    <div v-else>
      <TutorMessage :audioId="audioId" :autoPlay="autoPlay" :msg="msg" />
    </div>
  </div>
  <div v-else>
    <div v-if="isMessageLoading">
      <StudentMessage :isLoading="true" />
    </div>
    <div v-else>
      <StudentMessage :audioId="audioId" :autoPlay="autoPlay" :msg="msg" />
    </div>
  </div>
</template>

<script>
import TutorMessage from "./TutorMessage.vue";
import StudentMessage from "./StudentMessage.vue";

export default {
  name: "ChatMessage",
  components: {
    TutorMessage,
    StudentMessage,
  },
  props: {
    msg: String,
    speaker: String,
    audioId: String,
    isMessageLoading: { type: Boolean, default: false },
    autoPlay: { type: Boolean, default: false },
  },
  data() {
    return {
      isTutor: this.speaker === "tutor",
    };
  },
};
</script>

<style></style>
