<template>
  <section class="main-controls">
    <div class="record-button-container">
      <div v-if="showMicPermissionRequest">
        <MicPermissionRequest />
      </div>
      <button
        class="record"
        :disabled="disableButton || isRecordBlocked || wasRecordingCanceled"
        @click="toggleRecordButton"
      >
        <span class="inner-circle"></span>
        <i v-show="showSend" class="fas fa-arrow-up"></i>
      </button>
      <button class="cancel" v-show="showCancel" @click="cancelRecording">
        <i class="fas fa-times"></i>
      </button>
    </div>
  </section>
  <section class="sound-clip"></section>
</template>

<script>
import MicRecorder from "mic-recorder-to-mp3";
import "@fortawesome/fontawesome-free/css/all.css";
import MicPermissionRequest from "./MicPermissionRequest.vue";

export default {
  name: "AudioRecorder",
  components: {MicPermissionRequest},
  props: {
    disableButton: Boolean,
  },
  emits: ["newRecording"],
  data() {
    return {
      recorder: null,
      hasMicAccess: false,
      showMicPermissionRequest: false,
      showCancel: false,
      showSend: false,
      wasRecordingCanceled: false,
      recordedAudio: null,
      isRecording: false,
      isRecordBlocked: false,
    };
  },
  watch: {
    recordedAudio(newAudio) {
      this.$emit("newRecording", newAudio);
    },
  },
  methods: {
    async toggleRecordButton() {
      if (this.isRecording) {
        if (!this.wasRecordingCanceled) {
          this.recorder
            .stop()
            .getMp3()
            .then(([buffer, blob]) => {
              this.recordedAudio = blob;
            });
        }
        this.isRecording = false;
        this.resetRecordButton();
      } else {
        await this.checkMicAccess();
        if (!this.hasMicAccess) {
          console.warn("need mic access");
          return;
        }
        this.startRecording();
      }
    },
    async checkMicAccess() {
      console.debug("checking mic access");
      this.hasMicAccess = false; // assume no access until we confirm we have it
      const access = await Promise.race([
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then(stream => {
            stream.getTracks().forEach(track => track.stop());
            this.hasMicAccess = true; 
            this.showMicPermissionRequest = false;
          }).catch(error => {
            console.error('Microphone access denied or there was an error:', error);
            this.hasMicAccess = false; 
            this.showMicPermissionRequest = true;
          }), 
        new Promise(ignored => 
          setTimeout(() => {
            if (!this.hasMicAccess) { 
              this.showMicPermissionRequest = true;
            }
          }, 3000))
      ]);
      console.debug('Microphone access:', access);
    },
    startRecording() {
      this.isRecordBlocked = true;
      this.recorder.start().then(() => {        
        const record = document.querySelector(".record");
        record.style.background = "white";
        this.showCancel = this.showSend = true;
        this.isRecording = true;
        this.isRecordBlocked = false;
      });
    },
    cancelRecording() {
      this.wasRecordingCanceled = true;
      this.toggleRecordButton();
    },
    resetRecordButton() {
      this.recorder.stop();
      const record = document.querySelector(".record");
      record.style.background = "";
      record.style.color = "";

      this.wasRecordingCanceled = false;
      this.showCancel = this.showSend = false;
    },
  },
  mounted() {
    this.recorder = new MicRecorder({
      bitRate: 192,
    });
  },
};
</script>

<style>
.record {
  border-radius: 50%;
  background-color: rgb(238, 57, 57);
  color: white;
  width: 50px;
  height: 50px;
  border: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.record:hover {
  background-color: rgb(438, 57, 57);
}

.record:disabled {
  background-color: grey;
}

.record i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
}

.record-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancel {
  background-color: transparent;
  border-radius: 50%;
  color: rgb(0, 0, 0);
  cursor: pointer;
  margin-left: 10px;
}

.record:active .inner-circle {
  background-color: white;
}

.record:disabled .inner-circle {
  background-color: #673b3b;
}

.inner-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: darkred;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
</style>
