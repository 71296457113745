<template>
  <div>
    <div
      class="modal fade"
      id="userModal"
      tabindex="-1"
      aria-labelledby="userModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="userModalLabel">
              Please Allow Microphone Access
            </h5>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger" role="alert">
              In order to record your responses, we need access to your microphone.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  name: "MicPermissionRequest",
  data() {
    return {
      modal: null,
      hasMicAccess: false,
      micCheckInterval: null,
    };
  },
  methods: {
    async getMicAccess() {            
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.hasMicAccess = true;
        clearInterval(this.micCheckInterval);
        stream.getTracks().forEach(track => track.stop());
        this.modal.hide();
      } catch (error) {
        console.error('Microphone access denied or there was an error:', error);
        this.hasMicAccess = false;
      }
    },
  },
  mounted() {
    this.modal = new Modal(document.getElementById("userModal"), {
      keyboard: false,
      backdrop: "static",
    });
    this.modal.show();
    // check if we have mic access
    // user might enable it manually, 
    // and we have no way to watch except to request the mic and see if we get it
    this.micCheckInterval = setInterval(() => {
      this.getMicAccess();
    }, 2000);
  },
  beforeUnmount() {
    this.modal.hide();
  },
};
</script>
