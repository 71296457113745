import { reactive } from "vue";

export const flagsStore = reactive({
  flags: {},
  importFlags() {
    let r = require.context("@/assets/flags/", false, /\.svg$/);
    let flags = {};
    r.keys().map((item) => {
      flags[item.replace("./", "")] = r(item);
    });
    this.flags = flags;
  },
  getFlag(countryCode) {
    if (Object.keys(this.flags).length === 0) this.importFlags();
    return this.flags[countryCode + "-flag.svg"];
  },
});
