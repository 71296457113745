<template>
  <div>
    <div
      class="modal fade"
      id="languageModal"
      tabindex="-1"
      aria-labelledby="languageModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="languageModalLabel">New Language</h5>
          </div>
          <div class="modal-body">
            <!-- Language field -->
            <div class="mb-3 languages">
              <label for="language" class="form-label">Select a Language</label>
              <li
                v-for="(language, code) in languages"
                :key="code"
                @click="selectLanguage(code)"
                :class="{ active: selectedLanguage === code }"
              >
                <img
                  :src="flagsStore.getFlag(code)"
                  :alt="language.altText"
                  class="flag"
                />
                {{ language.name }}
              </li>
            </div>
            <!-- Reason field -->
            <div class="mb-3">
              <label for="reason" class="form-label"
                >Why are you learning this language?</label
              >
              <select id="reason" v-model="selectedReason" class="form-select">
                <option v-for="reason in reasons" :key="reason">
                  {{ reason }}
                </option>
              </select>
            </div>
            <!-- Proficiency field -->
            <div class="mb-3">
              <label for="proficiency" class="form-label"
                >What's your current proficiency?</label
              >
              <select
                id="proficiency"
                v-model="selectedProficiency"
                class="form-select"
              >
                <option v-for="level in proficiencyLevels" :key="level">
                  {{ level }}
                </option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <!-- Submit button -->
            <button
              class="btn btn-primary"
              @click="submitForm"
              :disabled="!fieldsAreValid"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import Languages from "@/data/Language";
import { authStore } from "@/store/auth";
import { flagsStore } from "@/store/countryFlags.js";
import { getDatabase, ref, update } from "firebase/database";

export default {
  name: "NewLanguageOnboarding",
  data() {
    return {
      modal: null,
      selectedLanguage: null,
      selectedReason: null,
      selectedProficiency: null,
      languages: Languages,
      reasons: [
        "Career",
        "Travel",
        "Fun",
        "Relationships",
        "Culture",
        "School",
        "Other",
      ],
      proficiencyLevels: ["Intermediate", "Upper Intermediate", "Advanced"],
      flagsStore: flagsStore,
    };
  },
  methods: {
    selectLanguage(code) {
      this.selectedLanguage = code;
    },
    submitForm() {
      const userId = authStore.getUserId();
      const updates = {};
      updates[`/users/${userId}/selectedLanguage`] = this.selectedLanguage;
      updates[
        `/users/${userId}/languagePreferences/${this.selectedLanguage}/proficiency`
      ] = this.selectedProficiency;
      updates[
        `/users/${userId}/languagePreferences/${this.selectedLanguage}/reasonForLearning`
      ] = this.selectedReason;

      update(ref(getDatabase()), updates)
        .then(() => {
          // Handle successful update
        })
        .catch((error) => {
          // Handle error
        });

      this.modal.hide();
    },
  },
  computed: {
    fieldsAreValid() {
      return (
        this.selectedLanguage && this.selectedReason && this.selectedProficiency
      );
    },
  },
  mounted() {
    this.modal = new Modal(document.getElementById("languageModal"), {
      keyboard: false,
      backdrop: "static",
    });
    this.modal.show();
  },
};
</script>

<style>
.flag {
  width: 20%;
  height: auto; /* To maintain aspect ratio */
  border: 1px solid #a7a7a7;
}
.languages li {
  list-style-type: none; /* This removes the bullet points */
  border: 1px solid #000;
  margin-top: 5px;
  text-align: left;
  transition: transform 0.3s ease-in-out;
}
.languages li:hover {
  transform: scale(
    1.05
  ); /* This scales the li element up slightly when hovered */
  cursor: pointer;
}
.languages li.active {
  background-color: #f0f0f0;
  transform: scale(1.05); /* This scales the active li up slightly */
}
</style>
