const qaFirebaseConfig = {
  apiKey: "AIzaSyCzopGHiD7DNIu1HviB5-k6kMG4N4snbPs",
  authDomain: "articulearn-qa.firebaseapp.com",
  projectId: "articulearn-qa",
  storageBucket: "articulearn-qa.appspot.com",
  messagingSenderId: "83248458712",
  appId: "1:83248458712:web:382c5025fe80052ab960ad",
  databaseURL: "https://articulearn-qa-default-rtdb.firebaseio.com/",
};

const prodFirebaseConfig = {
  apiKey: "AIzaSyBIZjNqrU-g7hxHEW--AgQPqa8VMnL0cRU",
  authDomain: "ArticuLearnAI.com",
  projectId: "spanishai",
  storageBucket: "spanishai.appspot.com",
  messagingSenderId: "258707100674",
  appId: "1:258707100674:web:51d5729482d6348d51c31b",
  databaseURL: "https://spanishai-default-rtdb.firebaseio.com/",
};

const firebaseConfig =
  process.env.NODE_ENV === "production" ? prodFirebaseConfig : qaFirebaseConfig;

export default firebaseConfig;
