<template>
  <div class="d-flex justify-content-end mb-4">
    <div
      class="msg_container"
      :style="{ 'min-width': isLoading ? '0' : '20%' }"
    >
      <div v-if="isLoading">
        <MessageLoading />
      </div>
      <div v-else>
        {{ msg }}
        <AudioPlayer :audioId="audioId" :isAutoPlay="autoPlay"/>
      </div>
    </div>
    <div class="img_cont_msg">
      <img
        src="https://therichpost.com/wp-content/uploads/2020/06/avatar2.png"
        class="rounded-circle user_img_msg"
      />
    </div>
  </div>
</template>

<script>
import MessageLoading from "./MessageLoading.vue";
import AudioPlayer from "./AudioPlayer.vue";

export default {
  name: "StudentMessage",
  props: {
    msg: String,
    audioId: String,
    isLoading: { type: Boolean, default: false },
    autoPlay: { type: Boolean, default: false },
  },
  components: {
    AudioPlayer,
    MessageLoading,
  },
};
</script>

<style scoped>
.img_cont_msg {
  height: 40px;
  width: 40px;
}
.user_img_msg {
  height: 40px;
  width: 40px;
  border: 1.5px solid #f5f6fa;
}
.msg_container {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border-radius: 25px;
  background-color: #F9DBC0;
  padding: 10px;
  position: relative;
  max-width: 66%;
  width: auto;
  text-align: center;
  padding-left: 20px;
}

@media (max-width: 768px) {
  .msg_container {
    width: 75%;
    max-width: none;
  }
}

@media (max-width: 480px) {
  .msg_container {
    width: 100%;
    max-width: none;
  }
}
</style>
