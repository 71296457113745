<template>
  <div class="chat-audio">
    <audio
      controls
      controlslist="nodownload nofullscreen"
      :id="audioId"
      :src="audioUrl"
      :type="audioType"
    ></audio>
  </div>
</template>

<script>
import { cloudFunctions } from "@/store/cloudFunctions.js";
import { audioConfig } from "@/store/audioConfig";
import { authStore } from "@/store/auth";

export default {
  props: {
    audioId: String,
    isAutoPlay: Boolean,
  },
  data() {
    return {
      audioUrl: null,
      audioType: `${audioConfig.mimeType}`,
    };
  },
  methods: {
    playMessage() {
      const audioElement = document.getElementById(this.audioId);
      audioElement.addEventListener("canplay", function () {
        audioElement.play();
      });
    },
  },
  created() {
    const params = new URLSearchParams({
      audioId: this.audioId,
    });
    const url = cloudFunctions.getAudioApi() + "?" + params;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authStore.getIdToken(),
      },
    })
      .then((response) => response.blob())
      .then((data) => {
        this.audioUrl = URL.createObjectURL(data);
        if (this.isAutoPlay) {
          this.playMessage();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
};
</script>

<style>
.chat-audio audio {
  width: 100%;
  height: 40px;
  background-color: #f1f0f0;
  border-radius: 20px;
  outline: none;
  border: none;
  padding: 5px;
}

.chat-audio audio::-webkit-media-controls-volume-control-container,
.chat-audio audio::-webkit-media-controls-time-remaining-display {
  display: none !important;
  max-width: 0;
}
</style>
