import { reactive } from "vue";

// docs: https://vuejs.org/guide/scaling-up/state-management.html#simple-state-management-with-reactivity-api
// todo: make sure we need an API first. Lots of times, we can just access the data directly from the client.
export const cloudFunctions = reactive({
  continueChatApi: "https://continuechat-uply2xtwgq-uc.a.run.app",
  continueChatApiQa:
    "https://us-central1-articulearn-qa.cloudfunctions.net/continueChat",
  continueChatApiLocal:
    "http://127.0.0.1:5001/articulearn-qa/us-central1/continueChat",
  audioApi: "https://getaudio-uply2xtwgq-uc.a.run.app",
  audioApiQa: "https://us-central1-articulearn-qa.cloudfunctions.net/getAudio",
  audioApiLocal: "http://localhost:5001/articulearn-qa/us-central1/getAudio",
  getContinueChatApi() {
    if (isLocalhost()) {
      console.log("hitting local getContinueChatApi");
      return this.continueChatApiLocal;
    } else if (isProd()) {
      return this.continueChatApi;
    }
    return this.continueChatApiQa;
  },
  getAudioApi() {
    if (isLocalhost()) {
      console.log("hitting local getAudioApi");
      return this.audioApiLocal;
    } else if (isProd()) {
      return this.audioApi;
    }
    return this.audioApiQa;
  },
});

function isLocalhost() {
  return location.hostname === "localhost" || location.hostname === "127.0.0.1";
}

function isProd() {
  return location.hostname === "articulearnai.com";
}
