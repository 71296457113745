<template lang="en">
  <NavigationBar/>
  <div class="main-intro">
    <div class="left-section">
      <h1 class="title">About Us</h1>
      <p class="content">
      We are a team of passionate language learners who understand the struggles and 
      challenges that come with mastering a new language, such as finding resources for 
      specific dialects, opportunities to practice speaking, and materials catered to 
      advanced learners.
      <br>
      <br>
      In 2023, we began our journey to create an application that would address the 
      specific hurdles that intermediate+ language learners like us encounter on their path to 
      fluency.
      </p>
    </div>
    <div class="about-us-hero">
      <img src="@/assets/about-us-hero.jpg" alt="Hero image" class="hero-image"/>
    </div>
  </div>
  <div class="founder-container">
    <div class="oval">
      <img src="@/assets/mathurshan.jpg" alt="Pic of Mathurshan">
      <h3>Mathurshan</h3>
      <p>Mathurshan first started learning Spanish in 6th grade. He became more curious about 
      language learning after graduating college. He started to work harder to retain his Spanish and 
      recover his native tongue, Tamil. In 2018, he started to learn Japanese. Mathurshan has traveled 
      to countries like Mexico, Argentina, Spain, and Japan immersing himself in their culture and language. 
      Throughout his language-learning journey, Mathurshan faced various challenges that ultimately 
      inspired him to develop an innovative app to help others overcome similar obstacles.</p>
    </div>
    <div class="oval">
      <img src="@/assets/delu.jpg" alt="Pic of Delu">
      <h3>Delu</h3>
      <p>Delu's journey began with a focus on enhancing her fluency in her native Sri Lankan Tamil 
      dialect. She also studied Spanish and Korean in school, actively seeking
      out diverse tools to sharpen her language skills. Driven by her passion 
      for language learning and maintenance, Delu joined forces with Mathurshan to further their 
      shared vision of empowering language learners.</p>
    </div>
    <div class="oval">
      <img src="@/assets/sean.png" alt="Pic of Sean">
      <h3>Sean</h3>
      <p>Sean has enjoyed language learning since starting Spanish lessons in the 5th grade. This 
      culminated in a Spanish minor in college, including a 6-month study abroad program in Chile. 
      It was during this program that Sean realized just how critical immersion is in mastering 
      speaking and listening abilities for another language, and how important fluency in a shared 
      language is for connecting with people across the world. So when Mathurshan approached him with 
      the concept of a language learning app that can provide the benefits of interacting with a 
      native speaker but in a more accessible way, it was a no-brainer to jump on board.</p>
    </div>
  </div>
  <FooterSection/>
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";
import FooterSection from "@/components/FooterSection.vue";
import { useHead } from "@unhead/vue";

export default {
  components: { NavigationBar, FooterSection },
  name: "AboutUsView",
  data() {
    return {
      socialImage: require("@/assets/about-us-hero.jpg"),
    };
  },
  computed: {
    ogImageUrl() {
      // Add socialImage to URL
      return `https://articulearnai.com${this.socialImage}`;
    },
  },
  mounted() {
    useHead({
      title: "ArticuLearn - About Us",
      meta: [
        {
          name: "description",
          content:
            "Explore the story behind our innovative application and meet the passionate individuals driving its development.",
        },
        {
          name: "og:title",
          content: "ArticuLearn - About Us",
        },
        {
          name: "og:description",
          content:
            "Explore the story behind our innovative application and meet the passionate individuals driving its development.",
        },
        { name: "og:type", content: "website" },
        {
          name: "og:image",
          content: this.ogImageUrl,
        },
        { name: "og:url", content: "https://articulearnai.com/about-us" },
      ],
    });
    window.prerenderReady = true;
  },
};
</script>

<style scoped>
.main-intro {
  margin-top: 100px;
  background: linear-gradient(to right, #17151e 85%, #683f2f);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.left-section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.title {
  padding: 20px;
  padding-top: 50px;
  color: #fff;
  font-size: 43px;
  font-weight: bold;
  text-align: left;
  font-family: "Open Sans", sans-serif;
}

.content {
  padding: 20px;
  color: #fff;
  font-size: 16px;
  text-align: left;
  font-family: "Open Sans", sans-serif;
  max-width: 300vh;
}

.about-us-hero {
  padding: 20px;
  padding-top: 90px;
}

.hero-image {
  max-width: 70vh;
  height: auto;
  border-radius: 10px;
  mask-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 1) 10%,
    rgba(0, 0, 0, 1) 49%,
    rgba(0, 0, 0, 0) 100%
  );
}

.founder-container {
  padding-top: 80px;
  padding-bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
}

.oval {
  flex: 0 0 30%;
  height: auto;
  text-align: center;
  margin-bottom: 20px;
}

.oval img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.oval h3 {
  margin: 0;
  padding-top: 20px;
  font-size: 25px;
}

.oval p {
  padding-top: 20px;
  margin: 0;
  font-size: 14px;
}

/* Styles for mobile screens */
@media (max-width: 767px) {
  .main-intro {
    flex-direction: column;
    background: #17151e;
  }

  .about-us-hero {
    order: -1;
  }

  .hero-image {
    max-width: 90%;
  }

  .founder-container {
    flex-direction: column;
    align-items: center;
  }
}

/* Styles for large screens */
@media (min-width: 2000px) {
  .oval img {
    width: 400px;
    height: 400px;
  }

  .oval h3 {
    font-size: 50px;
  }

  .oval p {
    font-size: 25px;
  }
}
</style>
