const Languages = Object.freeze({
  "zh-cn": {
    name: "Chinese (Mandarin)",
    altText: "China Flag",
  },
  "zh-hk": {
    name: "Chinese (Cantonese)",
    altText: "China Flag",
  },
  "en-us": {
    name: "English (American)",
    altText: "America Flag",
  },
  "en-gb": {
    name: "English (British)",
    altText: "Britain Flag",
  },
  "fr-fr": {
    name: "French",
    altText: "France Flag",
  },
  "fr-ca": {
    name: "French (Canadian)",
    altText: "Canada Flag",
  },
  de: {
    name: "German",
    altText: "Germany Flag",
  },
  ja: {
    name: "Japanese",
    altText: "Japan Flag",
  },
  ko: {
    name: "Korean",
    altText: "Korea Flag",
  },
  "pt-br": {
    name: "Portuguese (Brazilian)",
    altText: "Brazil Flag",
  },
  "pt-pt": {
    name: "Portuguese (European)",
    altText: "Portugal Flag",
  },
  ru: {
    name: "Russian",
    altText: "Russia Flag",
  },
  "es-mx": {
    name: "Spanish (Mexican)",
    altText: "Mexico Flag",
  },
  "es-es": {
    name: "Spanish (European)",
    altText: "Spain Flag",
  },
  "ta-in": {
    name: "Tamil (Indian)",
    altText: "India Flag",
  },
});

const sortedLanguages = Object.fromEntries(
  Object.entries(Languages).sort((a, b) => a[1].name.localeCompare(b[1].name))
);

export default sortedLanguages;
